<template>
  <div
    class="flex items-center justify-start w-full h-[100vh]"
    style="background: #e9ecef"
  >
    <div class="container">
      <h1 class="mb-3 text-4xl font-medium uppercase">
        {{ $t('common.503_title') }}
      </h1>

      <p
        v-html="
          $t('common.503_description').replace(
            'mailto',
            'mailto:contact@benelli.bike'
          )
        "
        class="mb-5 text-xl font-light lead"
      />
      <Btn
        v-text="$t('common.home_page')"
        @click="() => gotoHome()"
        color="gradient"
        class="w-auto px-2 py-3 text-xl font-semibold leading-5 text-white uppercase rounded-lg font-suiza-semibold bg-primary"
      ></Btn>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  error: {
    type: Object,
    default: () => {},
  },
})
function gotoHome() {
  if (import.meta.browser) {
    window.location.href = '/'
  }
}
</script>
