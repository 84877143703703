export const useAppStore = defineStore("app", {
  state: () => ({
    forceReload: false,
    acceptedCookies: false,
    countryLocale: null
  }),

  actions: {
    setForceReload(bool) {
      this.forceReload = bool;
    },
    setAcceptedCookies() {
      const nuxtApp = useNuxtApp()
      nuxtApp.$cookies.set("acceptedCookies", true, {
        path: "/",
      });
      this.acceptedCookies = true
    },
    setCountryLocale(countryLocale) {
      this.countryLocale = countryLocale
    }
  },
  persist: true,
});