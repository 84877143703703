<template>
  <div class="flex items-center justify-start w-full h-[100vh] bg-[#e9ecef]">
    <div class="container">
      <h1
        class="mb-3 text-6xl font-semibold tracking-wide uppaercase font-universe-lt-std"
      >
        {{ $t('common.404_title') }}
      </h1>
      <p
        class="mb-5 text-3xl font-light tracking-wide lead font-universe-lt-std"
      >
        {{ $t('common.404_description') }}
      </p>
      <Btn
        v-text="$t('common.home_page')"
        @click="() => gotoHome()"
        color="gradient"
        class="w-auto px-2 py-3 text-xl font-semibold leading-5 text-white uppercase rounded-lg font-suiza-semibold bg-primary"
      ></Btn>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  error: {
    type: Object,
    default: () => {},
  },
})
function gotoHome() {
  if (import.meta.browser) {
    window.location.href = '/'
  }
}
</script>
