<template>
  <div class="items-center jumbotron min-vh-100">
    <div class="w-full">
      <div class="">
        <!-- eslint-disable-next-line vue/require-component-is -->
        <component :is="errorPage" :error="error" />
      </div>
    </div>
  </div>
</template>

<script setup>
import error404 from '~/components/errors/404'
import error500 from '~/components/errors/500'
import error503 from '~/components/errors/503'
import { useMainStore } from '@/store/index'

const props = defineProps({
  error: {
    type: Object,
    default: () => {},
  },
})

const errorPage = computed(() => {
  if (props.error.statusCode === 404) {
    return error404
  } else if (props.error.statusCode === 503) {
    return error503
  }

  // catch everything else
  return error500
})

const nuxtApp = useNuxtApp()
const store = useMainStore()
const route = useRoute()
const defaultRoute = ref(null)

// onServerPrefetch(async () => {
try {
  if (Object.keys(route.params).length) {
    const [lastSelectedCountry, lastSelectedLocale] =
      route.params.country.split('-')

    if (!lastSelectedCountry || lastSelectedCountry == undefined) {
      await store.loadTranslationJsonData('int-en')
      defaultRoute.value = '/int-en'
    } else {
      await updateCall(lastSelectedCountry, lastSelectedLocale)
    }
  } else if (route.fullPath && route.fullPath.split('/').length > 1) {
    const routeCountrySlug = route.fullPath.split('/')[1]
    if (routeCountrySlug.includes('-')) {
      const [lastCountry, lastLocale] = routeCountrySlug.split('-')

      if (!lastCountry || lastLocale == undefined) {
        await store.loadTranslationJsonData('int-en')
        defaultRoute.value = '/int-en'
      } else {
        await updateCall(lastCountry, lastLocale)
      }
    } else {
      defaultRoute.value = '/int-en'
      await store.loadTranslationJsonData('int-en')
    }
  } else {
    defaultRoute.value = '/int-en'
    await store.loadTranslationJsonData('int-en')
  }
} catch (e) {
  console.log(e)
}
// });

async function updateCall(lsc, lsl) {
  const { data } = await useAPI('api/sites')

  // Check if the stored country in cookie exists as an oficial site
  const previousSite = data.value.find((country) => country.slug === lsc)

  if (previousSite) {
    const redirectUrlLocale = previousSite.urls.find(
      (locale) => locale.locale_code === lsl
    )

    if (
      !redirectUrlLocale &&
      previousSite &&
      previousSite.urls.length &&
      previousSite.urls[0]
    ) {
      await store.loadTranslationJsonData(
        lsl + '-' + previousSite.urls[0].locale_code
      )
      defaultRoute.value = previousSite.urls[0].url
    } else {
      await store.loadTranslationJsonData(lsc + '-' + lsl)
      defaultRoute.value = redirectUrlLocale.url
    }
  } else {
    defaultRoute.value = '/int-en'
    await store.loadTranslationJsonData('int-en')
  }
}
definePageMeta({
  layout: 'blank',
})
</script>

<style lang="scss">
body {
  background-color: white !important;
}
</style>
